import * as React from "react"
import styled from "styled-components";
import { Card, Flex, Grid, Tag, Typography } from "antd";
const { useBreakpoint } = Grid;
const { Title, Text } = Typography;
import DataSources from "./DataSources";
import { PrimaryButton } from "./shared/Buttons";
import ExpectationPreview from "./shared/ExpectationPreview";

const Container = styled(Card)<{$lg?: boolean}>`
    border: ${props => `1px solid ${props.theme.colors.gray}`};
    border-radius: ${props => props.theme.borderRadius.md};
    color: ${props => props.theme.colors.darkGray};
    height: 100%;
    min-height: 8rem;
    
    .ant-card-head {
        padding: ${({$lg}) => $lg ? "0 1rem" : "0.5rem 1rem"};
    }
    
    .ant-card-head-title {
        white-space: ${({$lg}) => $lg ? "nowrap" : "unset"};
    }

    .ant-card-body {
        padding: 0.625rem 1rem 1.75rem;
    }

    .ant-card-actions {
        border-color: ${props => props.theme.colors.gray};
        border-radius: ${props => `0 0 ${props.theme.borderRadius.md} ${props.theme.borderRadius.md}` };
    }
    
    .ant-card-head-wrapper {
        ${({$lg}) => !$lg && `
            align-items: flex-start;
            flex-direction: column;
            gap: 0.5rem;
        `}
    }

    .ant-card-extra {
        ${({$lg}) => !$lg && `
            margin-left: 0;
        `}
    }
`

const DataQualityIssueTag = styled(Tag)`
    border-radius: 0;
    font-size: ${props => props.theme.fontSize.sm};
    padding: 0.2rem 0.5rem;
    
    &:last-child {
        margin-right: 0;
    }
`

const DataSourcesContainer = styled(Flex)`
    font-weight: ${props => props.theme.fontWeight.semibold};
`

const StyledTitle = styled(Title) `
    color: ${props => props.theme.colors.gray};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin: 0;

    && {
        margin-bottom: 0;
    }
`

const StyledText = styled(Text)`
    display: block;
    min-height: 3rem
`

const DataQualityIssues = ({ dataQualityIssues }) => (
    <>
        {dataQualityIssues.map((dataQualityIssue: string, index: number) =>
            <DataQualityIssueTag key={index}>{dataQualityIssue}</DataQualityIssueTag>
        )}
    </>
);

const CardActions = ({expectation}) => {
     return (
        <Flex gap="small" justify="end" style={{ padding: "0.25rem 0.75rem" }}>
            <ExpectationPreview expectation={expectation}/>
            <PrimaryButton href={`/expectations/${expectation.link}`} style={{ width: 120 }}>See more</PrimaryButton>
        </Flex>
    );
};

const ExpectationCard = ({expectation}) => {
    const { lg } = useBreakpoint()

    return (
        <Container
            title={<StyledTitle level={5}>{expectation.title}</StyledTitle>}
            extra={<DataQualityIssues dataQualityIssues={expectation.data_quality_issues}/>}
            actions={[<CardActions expectation={expectation}/>]}
            $lg={lg}
        >
            <StyledText>{expectation.short_description}</StyledText>
            <DataSourcesContainer gap={6}>Data source: <DataSources
                dataSources={expectation.supported_data_sources}/></DataSourcesContainer>
        </Container>
    );
};

export default ExpectationCard;
