/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import "./static/style.less";
import SEO from "../components/seo";
import ExpectationsPage from "../components/galleryV2/ExpectationsPage";

class ExpectationsHome extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { bannerData, seoMetadata } = data.allContentfulPage.nodes && data.allContentfulPage.nodes.length > 0 ? data.allContentfulPage.nodes[0] : [];
    return (
      <Layout bannerData={bannerData}>
         {seoMetadata && (
        <SEO
          title={seoMetadata.title}
          description={seoMetadata.description}
        />
      )}
        <ExpectationsPage/>
      </Layout>
    );
  }
}
export default ExpectationsHome;
export const pageQuery = graphql`
query expecPage {
  allContentfulPage(filter: {slug: {eq: "expectations"}}) {
    nodes {
      id
      seoMetadata {
        description
        keywords
        ogImage {
          gatsbyImageData
          title
        }
        title
      }
      bannerData {
        bannerImage {
          gatsbyImageData(width:30)
        }
        bannerSlug
        bannerText {
          raw
        }
        label
        link
      }
    }
  }
}
`;
