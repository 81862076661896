import * as React from "react";
import styled from "styled-components";
import {Grid} from "antd";
const { useBreakpoint } = Grid;

const Wrapper = styled.section`
    background-color: ${({theme}) => theme.colors.white};
    background-image: url("/images/bg-data-hexagons.png");
    background-repeat: no-repeat;
    background-size: cover;
`;

const Content = styled.div`
    padding: 2rem;
    margin: 0 auto;
    max-width: 1200px;
    color: ${({theme}) => theme.colors.darkGray};
`;

const Title = styled.h1<{ isMobile: boolean }>`
  font-weight: bold;
  line-height: 1.2;
  font-family: Montserrat, sans-serif;
  margin: 1rem 0;
  &&& {
      font-size: ${props => (props.isMobile ? 42 : 56)}px;
  }  
`;
const OrangeText = styled.span`
  color: ${({theme}) => theme.colors.orange};
`;

const PageDescription = styled.span`
  font-size: 24px;
  line-height: 29px;
  font-family: Source Sans Pro, sans‑serif;
`;
const ExpectationsHero = () => {
    const { xs } = useBreakpoint();

    return(<Wrapper>
        <Content>
            <Title isMobile={xs}>Explore <OrangeText>Expectations</OrangeText></Title>
            <PageDescription>Expectations are assertions about your data. In Great Expectations, those assertions are
                expressed in a declarative language in the form of simple, human-readable Python
                methods.</PageDescription>
        </Content>
    </Wrapper>)
};

export default ExpectationsHero;
