import * as React from "react"
import ExpectationCard from "../galleryV2/ExpectationCard";
import {Col, Row} from "antd";

const ExpectationsList = ({ expectations }) => {
    return (
        <Row gutter={[24, 22]}>
            {expectations.map(expectation =>
                <Col xs={24} md={24} lg={12} key={expectation.key}>
                    <ExpectationCard expectation={expectation}/>
                </Col>
            )}
        </Row>
    )
}

export default ExpectationsList;
