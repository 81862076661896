import * as React from "react";
import ProvidersWrapper from "./ProvidersWrapper";
import ExpectationsHero from "./ExpectationsHero";
import ExpectationsSection from "./ExpectationsSection";
import isLoading from "./shared/IsLoading";
import CustomSkeleton from "./shared/Skeleton";

const ExpectationsPage = () => {
    const loading = isLoading();

    if (loading) {
        return <CustomSkeleton/>
    }

    return (
        <ProvidersWrapper>
            <ExpectationsHero/>
            <ExpectationsSection/>
        </ProvidersWrapper>
    );
};

export default ExpectationsPage;
